import React, { useEffect,useState } from 'react';
import { useSelector } from "react-redux";
import { getCslData, selectCslData, getLoadingState,deleteCslData} from '../../redux/slices/cslSlice';
import { OrcaTable } from '../../Components/common/OrcaTable';
import store from '../../redux/store';
import { LdsButton, LdsModal, useLdsModal } from '@elilillyco/ux-lds-react';
import { selectUserData } from "../../redux/slices/userSlice";

export const ConsolidatedSuggestionLibrary = () => {
  const data = useSelector(selectCslData);
  const loading = useSelector(getLoadingState);
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [ modalHeading, setModalHeading ] = useState('Delete Request');
  const [ formData, setFormData ] = useState({});
  const userData = useSelector(selectUserData);

  useEffect(() => {
    store.dispatch(getCslData());
  }, []);

  const actionButtons = [
    {
      iconName: 'TrashSimpleFill',
      iconLabel: 'Delete',
      onClick: (row) => {
        let newrow = { ...row };
        setFormData(newrow);
        setModalHeading('Delete Insight');
        setIsModalOpen(true);
      },
      isVisible: (userRole, row) => {console.log(row, userRole, userData); return (userRole === 'Requester' || userRole === 'Admin') && row['requester'] === userData.name && row.status === 'Draft'},
    }
  ];

  const DeleteCslForm = ({ formData }) => {

    return (
      <>
        <div className='box'>
        {"Are you sure you want to delete \""}{formData.request_key}{"\"?"}
        </div>
        { <LdsButton id="yesButton" value="delete" label="Delete" name="submit" style={{marginBottom:'10px', marginTop:'20px'}} onClick={() => deleteSubmit(formData)} type='submit'>Delete</LdsButton> }
        { <LdsButton id="noButton" value="cancel" label="Cancel" name="submit" style={{marginBottom:'10px', marginTop:'20px', float: 'right'}} onClick={() => setIsModalOpen(false)} type='submit'>Cancel</LdsButton> }
      </>
    );
  }

  const deleteSubmit = (formData) => {
    store.dispatch(deleteCslData(formData));
    setIsModalOpen(false);
  };

  return (
    <>
      <LdsModal
        modalId="deleteCslModal"
        open={isModalOpen}
        setModalOpen={setIsModalOpen}
        heading={modalHeading || "Delete Insight"}
      >
        {modalHeading === 'Delete Insight' && <DeleteCslForm formData={formData}/>}
      </LdsModal>
    <OrcaTable loading={loading} rows={data} showFilters actionButtons={actionButtons} title='Consolidated Insights Library' filterColumns={['capability', 'insight_key', 'requester', 'brand', 'indication', 'insight_category', 'br_text_type', 'status']} displayKey={{'insight_key':{'display': true}, 'requested_sprint':{'display': true}, 'br_insight_type':{'display': true}, 'cardnumber':{'display':true, 'minWidth':'100px'}, 'insight_status':{'display': true}, 'capability':{'display': true}, 'brand':{'display': true}, 'indication':{'display': true}, 'insight_category':{'display': true, 'minWidth': '400px'}, 'ta_priority':{'display': true, 'minWidth':'250px'}, 'business_rule':{'display': true, 'minWidth': '400px'}, 'insight_graph_metric_key':{'display': true, 'minWidth': '250px'}, 'bia_metrics':{'display': true, 'minWidth':'400px'}, 'bia_metric_based_on':{'display': true, 'minWidth':'250px'}, 'other_metrics':{'display': true, 'minWidth':'400px'}, 'trigger_criteria':{'display': true, 'minWidth':'400px'},'bia_trigger_based_on':{'display':true,'minWidth':'250px'}, 'new_metrics_ingest':{'display': true, 'minWidth':'240px'}, 'cloud_tag':{'display': true, 'minWidth':'200px'}, 'segment':{'display': true}, 'refresh_freq':{'display': true}, 'br_text_type':{'display': true}, 'br_insight_text':{'display': true, 'minWidth':'400px'}, 'br_suggestion_text':{'display': true, 'minWidth':'400px'}, 'fcap':{'display': true, 'minWidth':'100px'}, 'status': { 'display': true, 'title': 'Request Status' }, 'sales_force_team':{'display': true, 'minWidth':'300px'}}} cloneModal updateModal viewModal />
    </>
  );
};
