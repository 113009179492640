import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { OrcaFormOptions } from './OrcaFormOptions';
import { LdsIcon } from "@elilillyco/ux-lds-react";

export default function MultiSelectDropdown({ name, onChange, filterOptions, clearFilter }) {

  const [selectedOptions, setSelectedOptions] = useState([]);
  let inputValue = selectedOptions.length > 0 ? selectedOptions[0].label : '';

  useEffect(() => {
    if (clearFilter && selectedOptions?.length > 0) {
      setSelectedOptions([]);
      inputValue = '';
    }
  }, [clearFilter, selectedOptions]);

  
  const validateValue = (arr) => {
    const result = arr?.length > 0 && arr.filter(item => item.value !== null && item.value !== undefined && item.value !== "");
    return result
  }

  const options = validateValue(filterOptions[name]) || validateValue(OrcaFormOptions[name]) || [];

  const handleRemoveItem = (value) => {
    setSelectedOptions((prev) => {
      const updatedOptions = prev.filter((option) => option.value !== value);
      const filteredVal = updatedOptions.map(obj => obj.value).filter(item => item !== null && item !== undefined && item !== "");
      filteredVal && onChange(name, filteredVal);
      return updatedOptions;
    })
  };

  const handleChange = (newValue) => {
    const filteredVal = newValue.map(obj => obj.value).filter(item => item !== null && item !== undefined && item !== "");
    setSelectedOptions(validateValue(newValue));
    filteredVal && onChange(name, filteredVal);
  }

  return (
    <FormControl style={{ 'width': 200, 'top': '35px' }}>
      <Autocomplete
        multiple
        style={{
          height: 43,
          display: 'flex',
          flexWrap: 'wrap',
        }}
        sx={{
          '& .MuiInputLabel-outlined': {
            '&.Mui-focused': {
              color: '#9E9E9E'
            }
          },
          '& .MuiOutlinedInput-root': {
            borderColor: '#9E9E9E',
            '& fieldset': {
              borderColor: '#9E9E9E'
            },
            '&:hover fieldset': {
              borderColor: '#9E9E9E'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#9E9E9E',
            },
          },
        }} 
        id="multi-select-dropdown"
        limitTags={1}
        options={options}
        getOptionLabel={(option) => option.label}
        value={selectedOptions[0]}
        onChange={(event, newValue) => { handleChange(newValue) }}
        renderTags={(value, getTagProps) => {
          const { key } = getTagProps({ index: 0 });
          return selectedOptions?.length > 0 && (
            <div
              style={{
                width: '60%',
                overflow: 'hidden',
                display: 'flex',
                marginTop: '2px'
              }}>
              <Chip
                size='small'
                label={value[0]?.label}
                key={key}
                style={{ width: value.length > 1 ? '75%' : '100%' }}
              />
              {value.length > 1 && <span style={{ fontSize: 11, marginTop: '1px', maxWidth: '25%', overflow: 'hidden' }}> +{value?.length - 1}</span>}
            </div>
          )
        }
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={inputValue}
              fullWidth={false}
              label='Select'
              variant="outlined"
              height={30}
              style={{
                height: 30,
                width: '100%',
                maxWidth: 200,
                padding: 0,
                fontSize: 10,
                display: 'flex'
              }} />
          )
        }}
        size='small'
        renderOption={(props, option, { selected }) => (
          <li {...props}
            style={{
              backgroundColor: (selected && selectedOptions?.length > 0) ? '#f5f5f5' : 'transparent',
              display: 'flex',
              paddingRight: '20px',
              marginBottom: '2px',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            {option.label}
            {(selected && selectedOptions?.length > 0) && (
              <IconButton
                size="small"
                onClick={(e) => {
                  handleRemoveItem(option.value);
                }}
              >
                <LdsIcon
                  name='XCircleFill'
                  label="option select"
                  inline
                  style={{ color: 'black', alignSelf: 'flex-end' }}
                />
              </IconButton>
            )}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        disableCloseOnSelect
      />
    </FormControl>
  )
}